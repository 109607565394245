import React from 'react'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import EZShipBoxSideForm, { EZShipBoxBackdrop } from '../components/repairs/EZShipBoxSideForm'
import { Link } from 'gatsby'
import BrandRepairServices from '../components/repairs/BrandRepairServices'
import CustomerRepairLetters from '../components/repairs/CustomerRepairLetters'
import HaveQuestions from '../components/repairs/HaveQuestions'
import OurExperience from '../components/repairs/OurExperience'
import Section from '../components/cms/Section'
import PropTypes from 'prop-types'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'
import { TableOfContentsHTML } from '../components/TableOfContents'
import { CombineSections } from '../components/cms/SectionLayout'
import GrayAndSonsRepairs from '../components/repairs/GrayAndSonsRepairs'

const BrandLinks = ({ children, url, className }) => {
  const body = (
    <a
      className={
        (url ? 'duration-200 text-red-700 hover:underline' : 'text-gray-500') +
        (className ? ' ' + className : '')
      }
    >
      {children}
    </a>
  )
  return url ? <Link to={url}>{body}</Link> : body
}

export const RepairBlogCategorySectionRenderer = ({ data }) => {
  const [open, setOpen] = React.useState(false)
  const toggleForm = React.useCallback(() => setOpen(o => !o), [setOpen])
  const {
    page: { models, services },
    page,
  } = data
  const pageSections = CombineSections(page.sections || [])
  const [firstSection, secondSection, ...otherSections] = pageSections

  const getTitle = () => {
    if (page.brand) return page.brand
    if (page.tag && page.tag.length > 0) {
      return page.tag[0].location + ' ' + page.tag[0].category
    }
    return page.seo.title
  }
  processGatsbyImageDataMock(page.safeHeroImage)
  const breadcrumbs = [{ url: '/repairs', name: 'Repairs' }]

  return (
    <Layout
      className={'mx-auto'}
      breadcrumbs={breadcrumbs}
      submenu={
        <header
          className={
            'h-80 2xl:h-72 ' +
            ' group flex flex-row justify-center bg-gradient-to-t from-gray-200 to-transparent to-[20%] duration-300 lg:px-[8.33%] px-5'
          }
        >
          <div
            className={
              'hidden md:flex ' +
              ' relative w-1/2 h-full overflow-hidden max-w-[400px] ' +
              ' *:absolute *:top-[50%] *:-z-10 *:w-full *:-translate-y-[50%] *:grayscale *:duration-500 *:group-hover:grayscale-0'
            }
          >
            {page.safeHeroImage ? (
              <GatsbyImage
                image={getImage(page.safeHeroImage)}
                alt={page.brand + ' Watch Repairs'}
                imgStyle={{ objectFit: 'fit' }}
              />
            ) : (
              <StaticImage
                alt={page.brand + ' Watch Repairs'}
                src={'../images/1600-gns-site-banner-00.png'}
                loading="eager"
                imgStyle={{ objectFit: 'cover' }}
              />
            )}
          </div>
          <div className={'w-full max-w-[800px] flex flex-col items-start justify-center p-5'}>
            <h1 className="banner-h2-grow font-bold text-black uppercase flex flex-col">
              <b className="whitespace-pre-wrap order-2 leading-tight">{getTitle()} Watches</b>{' '}
              <span className="whitespace-pre text-base order-1">Repair & Service</span>
            </h1>
            <p className={'py-1 line-clamp-5'}>
              Gray & Sons Jewelers, located in Miami Beach, Florida is an independent watch service
              center, repairing {page.brand} watches since 1980.
            </p>

            <button className="gns-button" onClick={toggleForm}>
              QUOTE MY REPAIR
            </button>
          </div>
        </header>
      }
    >
      <SEO seo={page?.seo} title={page.brand + ' Watch Repairs'} />

      <section className=" relative flex flex-col lg:flex-row max-w-[1366px] mx-auto">
        <div className="w-full order-2 lg:order-1 px-5">
          {firstSection && <Section section={firstSection} />}
          {page.table_of_contents ? <TableOfContentsHTML sections={page.sections} /> : null}
          <section>
            <div className="flex flex-col items-center">
              <h2 className="underline-title my-5 ">
                <nobr>{page.brand} MODELS</nobr>
                WATCH REPAIR BLOGS
              </h2>
              <div className="grid w-full grid-cols-2 text-sm gap-y-5 lg:gap-y-8 md:grid-cols-3 md:text-base 2xl:grid-cols-4 ">
                {models.map((arrayElement, index) => {
                  return (
                    <BrandLinks
                      key={index}
                      className={arrayElement.className}
                      url={arrayElement.url}
                    >
                      {page.brand}
                      <br />
                      {arrayElement.title}
                    </BrandLinks>
                  )
                })}
              </div>
            </div>
            {secondSection && <Section section={secondSection} />}
            <div className="my-24">
              <h2 className="underline-title my-5">{page.brand} Repair Services</h2>
              <BrandRepairServices services={services} />
            </div>
          </section>
          {otherSections.map((section, index) => (
            <Section key={index} section={section} />
          ))}
          <OurExperience />
          <GrayAndSonsRepairs />
          <HaveQuestions />
        </div>
        <EZShipBoxSideForm open={open} toggleForm={toggleForm} />
      </section>
      <CustomerRepairLetters brand={page.brand} />
      <EZShipBoxBackdrop open={open} toggleForm={toggleForm} />
    </Layout>
  )
}

BrandLinks.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  className: PropTypes.string,
}

RepairBlogCategorySectionRenderer.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      brand: PropTypes.string,
      safeHeroImage: PropTypes.object,
      models: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      url: PropTypes.string,
      table_of_contents: PropTypes.bool,
      createdAt: PropTypes.string,
      publishedAt: PropTypes.string,
      updatedAt: PropTypes.string,
      seo: SEO.propTypes.seo,
      sections: PropTypes.arrayOf(Section.propTypes.section),
      tag: PropTypes.arrayOf(
        PropTypes.shape({
          location: PropTypes.string,
          service: PropTypes.string,
          category: PropTypes.string,
        })
      ),
      services: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
  }),
}
